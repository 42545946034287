import React, { useState, useEffect, useCallback } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { GetSampleDisplayText, reportStatusSelectOptions } from '../../Generic/Constants.js';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const rowTitles = ['N° Informe', 'Fecha Finalizado', 'Muestra', 'Densidad Húmeda', 'Humedad', 'Densidad Seca'];

const PuRevision = ({ setLoading, project }) => {

  const alert = useAlert();

  const [data, setData] = useState([]);

  const GetData = useCallback(() => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/revisionHelpers/puResults/'+project;

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      setData(res.data);      
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert, project, setLoading]);

  useEffect(() => {
    if (project) {
      GetData();
    }
  }, [project, GetData]);

  const RenderData = () => {
    return (
      <div className="d-flex overflow-auto">
        <div className="d-flex flex-column h-100 position-sticky start-0 bg-aliceblue border-end border-secondary" style={{minWidth: 'fit-content'}}>
          {rowTitles.map((header, headerIdx) => 
            <div key={headerIdx} className="p-1"> {header} </div>
          )}
        </div>
        {data.map((report, reportIdx) => {
          const statusProps = reportStatusSelectOptions.find(rs => rs.value === report.status);
          return (
            <div key={reportIdx} className="d-flex flex-column align-items-center h-100 text-center border-end border-secondary" style={{minWidth: 'fit-content'}}>
              <div className={"w-100 py-1 " + (statusProps?.colorClasses || '')} title={report.reportNumber + ' - ' + (statusProps?.display || ' - ')}> 
                <span className="fw-semibold px-1">{report.reportNumber}</span> 
              </div>
              <div className="p-1" style={{minWidth: '45px'}}> {report.finalizedDate || '-'} </div>
              <div className="d-flex">
                {report.data.map((rSection, rSectionIdx) =>
                  <div key={rSectionIdx} className={"px-1 " + (rSectionIdx === (report.data.length-1) ? "" : "border-end border-info border-opacity-25")} style={{minWidth: 'fit-content'}}>
                    {[GetSampleDisplayText(rSection.sample), rSection.field9, rSection.field15, rSection.field16]
                    .map((item, itemIdx) => <div key={itemIdx} className={"p-1 " + (itemIdx === 0 ? "fw-semibold" : "")} style={{minWidth: '45px'}}> {item || '-'} </div> )}
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="modal-table-outer-container mt-2">
      { (project && data.length) ? RenderData() : <span className="p-1">No hay ensayos disponibles</span> }
    </div>
  )
}

export default PuRevision